@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  font-family: F1Regular, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
  scroll-behavior: smooth;
}

body {
  color: #00095b;
}

a {
  color: #00095b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.25;
  font-family: "F1Medium";
  font-weight: 500;
}

@font-face {
  font-family: "F1Regular";
  src: local("F1Regular"), url("Fonts/F1/FordF-1-Regular.eot") format("eot"),
    url("Fonts/F1/FordF-1-Regular.ttf") format("ttf"),
    url("Fonts/F1/FordF-1-Regular.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "F1Medium";
  src: local("F1Medium"), url("Fonts/F1/FordF-1-Medium.eot") format("eot"),
    url("Fonts/F1/FordF-1-Medium.ttf") format("ttf"),
    url("Fonts/F1/FordF-1-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "F1Bold";
  src: local("F1Bold"), url("Fonts/F1/FordF-1-Bold.eot") format("eot"),
    url("Fonts/F1/FordF-1-Bold.ttf") format("ttf"),
    url("Fonts/F1/FordF-1-Bold.woff") format("woff");
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}
