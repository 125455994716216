@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  font-family: F1Regular, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
  scroll-behavior: smooth;
}

body {
  color: #00095b;
}

a {
  color: #00095b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.25;
  font-family: "F1Medium";
  font-weight: 500;
}

@font-face {
  font-family: "F1Regular";
  src: local("F1Regular"), url(../../static/media/FordF-1-Regular.0a0f1bb7.eot) format("eot"),
    url(../../static/media/FordF-1-Regular.13df5ac4.ttf) format("ttf"),
    url(../../static/media/FordF-1-Regular.004938b2.woff) format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "F1Medium";
  src: local("F1Medium"), url(../../static/media/FordF-1-Medium.d74bee3e.eot) format("eot"),
    url(../../static/media/FordF-1-Medium.1e18daef.ttf) format("ttf"),
    url(../../static/media/FordF-1-Medium.e5f7f082.woff) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "F1Bold";
  src: local("F1Bold"), url(../../static/media/FordF-1-Bold.522b9908.eot) format("eot"),
    url(../../static/media/FordF-1-Bold.2bb940c1.ttf) format("ttf"),
    url(../../static/media/FordF-1-Bold.9c27ebbf.woff) format("woff");
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

